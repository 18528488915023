import React from 'react';
import { graphql, navigate } from 'gatsby';
import { Row, Col, Card as RBCard, Container, Nav, CloseButton } from 'react-bootstrap';
import styled from 'styled-components';

import { ISLACEL_EMAIL, ISLACEL_PHONE, ISLACEL_PHONE_FORMATTED } from '../params';

import Layout from '../components/layouts/layout';
import SEO from '../components/seo';

import CallIconSvg from '../images/icon-call.svg';
import EmailIconSvg from '../images/icon-email.svg';
import PinIconSvg from '../images/icon-pin.svg';

const RootContainer = styled(Container)`
  min-height: calc(100vh - 320px);
`;

const Heading = styled.h1`
  font-size: 39px;
  @media (max-width: 1023px) {
    font-size: 27px;
  }
`;

const Subheading = styled.h2`
  font-size: 18px;
`;

const Card = styled(RBCard)`
  height: 100%;
  @media (max-width: 1023px) {
    height: auto;
  }
`;

const CardIcon = styled.svg`
  height: 48px;
  fill: #0d9953;
`;

const ContactPage = () => {
  const PhoneDescription = (
    <>
      <p>
        Nuestro Servicio de Atención al Cliente responde directamente a través del
      </p>
      <p className="fs-5 fw-bold">
        <a href={`tel:${ISLACEL_PHONE}`} className="stretched-link">{ISLACEL_PHONE_FORMATTED}</a>
      </p>
      <p className="fw-bold">
        <span className="text-success">Horario</span><br/>
        8:00am a 8:00pm EST, 365 días al año.
      </p>
      <p className="small">
        En promoción se extiende hasta las 10:00pm
      </p>
    </>
  );

  const EmailDescription = (
    <>
      <p>
        También puedes enviarnos un correo electrónico para remitirnos tus dudas o
        consultas. Te responderemos por mensaje o email lo antes posible.
      </p>
      <p className="fs-5 fw-bold">
        <a href={`mailto:${ISLACEL_EMAIL}`} className="stretched-link">{ISLACEL_EMAIL}</a>
      </p>
    </>
  );

  const MapDescription = (
    <>
      <p>
        Islacel Corporation <br />
        2500 NW 79th Ave, Suite 126, Doral, <br />
        FL 33122
      </p>
      <p className="fs-5 fw-bold">
        <a href="https://goo.gl/maps/kxvBMhESUNZztLCw9" target="_blank" rel="noopener noreferrer" className="stretched-link">Ver ubicación</a>
      </p>
    </>
  );

  const cards = [
    {
      icon: CallIconSvg,
      title: 'Contactar por teléfono',
      description: PhoneDescription,
    },
    {
      icon: EmailIconSvg,
      title: 'Contactar por email',
      description: EmailDescription,
    },
    {
      icon: PinIconSvg,
      title: 'Oficina',
      description: MapDescription,
    },
  ];

  const renderCards = () =>
    cards.map((item, index) => (
      <Col key={index} lg>
        <Card className="shadow-lg mb-3">
          <Card.Body className="d-flex">
            <div className="d-flex flex-column justify-content-between flex-grow-1">
              <div className="text-center flex-grow-1">
                <CardIcon as={item.icon} className="my-3" />
                <Subheading className="text-success">{item.title}</Subheading>
                {item.description}
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    ));

  return (
    <Layout
      headerProps={{
        disableLogin: true,
        NavigationOptions: <Nav.Link onClick={() => navigate(-1)}><CloseButton/></Nav.Link>,
      }}
    >
      <SEO title="Contáctanos" />

      <RootContainer className="d-flex flex-column align-items-center justify-content-center py-4 py-lg-6 text-center">
        <Heading className="text-success mb-0">
          ¿Necesitas ayuda con tu recarga Cubacel?
        </Heading>
        <Subheading className="my-3 my-lg-4">
          Nuestros agentes te ayudarán paso a paso en todo lo que necesites.
        </Subheading>

        <Container className="gx-0">
          <Row>{renderCards()}</Row>
        </Container>
      </RootContainer>
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
